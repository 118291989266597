import React from 'react'
import ContentContainer from '../containers/content'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    return (
      <ContentContainer>
        <SEO title="404: Not found" />
        <div className="page-container">
          <div className="custom-columns">
            <div className="custom-columns__column--body">
              <p>
                Page not found <br />
                Redirecting to homepage
              </p>
            </div>
          </div>
        </div>
      </ContentContainer>
    )
  }
  componentDidMount() {
    setTimeout(() => {
      window.location.href = '/'
    }, 3000)
  }
}

export default NotFoundPage
